import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import type { QueryResult } from 'react-apollo';
import type { WatchQueryFetchPolicy } from 'apollo-client';

import { fg } from '@confluence/feature-gating';

import { PageTreeQuery } from './queries/PageTreeQuery.graphql';
import type { PageTreeQuery as PageTreeQueryResponse } from './queries/__types__/PageTreeQuery';
import type { PageTreeRootLevelPages as PageTreeRootLevelPagesResponse } from './queries/__types__/PageTreeRootLevelPages';
import { PageTreeRootLevelPages as PageTreeRootLevelQuery } from './queries/PageTreeRootLevelQuery.graphql';
import { INITIAL_PAGE_SIZE } from './paginationLimits';
import type { PageTreeState } from './usePageTreeState';
import { usePageTreeState } from './usePageTreeState';
import { flattenTreeData, derriveStateFromData } from './data-transformers';
import { useLoadMorePages } from './useLoadMorePages';
import { usePageTreeChildren } from './usePageTreeChildren';
import { PAGE_TREE_STATUSES } from './pageTreeStatuses';
import type { GraphQLPageStatus } from './pageTreeStatuses';

export const generateQueryArgs = (
	targetPage,
	paginated,
	forceFetch,
	detached,
	spaceKey,
	isSuperAdmin,
) => {
	const query = targetPage ? PageTreeQuery : PageTreeRootLevelQuery;
	const defaultVariables = targetPage
		? {
				spaceKey,
				isSuperAdmin,
				statuses: PAGE_TREE_STATUSES,
				ptStatuses: PAGE_TREE_STATUSES,
				contentId: targetPage,
			}
		: {
				spaceKey,
				isSuperAdmin,
				statuses: PAGE_TREE_STATUSES,
				emojiTitleEnabled: true,
			};

	const variablesForPagination = {
		paginationLimit: INITIAL_PAGE_SIZE,
		...defaultVariables,
	};

	return {
		query,
		options: {
			fetchPolicy: detached ? 'no-cache' : forceFetch ? 'network-only' : 'cache-first',
			variables: paginated === true || !targetPage ? variablesForPagination : defaultVariables,
		},
	};
};

export const usePageTreeData = (
	spaceKey?: string,
	isSuperAdmin?: boolean,
	statuses?: GraphQLPageStatus[],
	onLoadMore?: (length, direction?: 'following' | 'previous') => void,
): {
	loadPreviousPages: () => void;
	loadFollowingPages: () => void;
	reloadChildren: () => void;
	queryChildren: any;
} => {
	const [state, actions] = usePageTreeState();
	const { focusedPageId } = state as PageTreeState;
	const { loadMorePages } = useLoadMorePages({ statuses });
	const { reloadChildren, queryChildren } = usePageTreeChildren({
		statuses,
		isSuperAdmin,
	});

	const loadMoreDirectionalPages = useCallback(
		async (direction: 'previous' | 'following') => {
			actions.setIsPageTreeLoading(true);
			const { mergedPages, rootLevelPages, error } = await loadMorePages(
				focusedPageId,
				spaceKey,
				isSuperAdmin,
				state.cursors,
				state.initialPages,
				direction,
			);

			if (error) {
				//toDo: handle loading previous / next errors here, then reset state so they can attempt to try again
				return;
			}

			const newState = derriveStateFromData(
				{ currentPage: mergedPages, rootLevelPages, space: state.space },
				spaceKey,
				state,
				false,
				undefined,
				false,
				state.isShowingBlankDraftsEnabled,
				state.isUnreadPagesEnabled,
			);

			actions.setStateWithUIAttributes(newState);
			const pages = flattenTreeData(mergedPages, rootLevelPages, state.isShowingBlankDraftsEnabled);
			actions.setIsPageTreeLoading(false);
			if (onLoadMore) {
				onLoadMore(Object.keys(pages).length, direction);
			}
		},
		[actions, loadMorePages, focusedPageId, isSuperAdmin, onLoadMore, spaceKey, state],
	);

	const loadPreviousPages = useCallback(
		() => loadMoreDirectionalPages('previous'),
		[loadMoreDirectionalPages],
	);
	const loadFollowingPages = useCallback(
		() => loadMoreDirectionalPages('following'),
		[loadMoreDirectionalPages],
	);

	return {
		loadPreviousPages,
		loadFollowingPages,
		reloadChildren,
		queryChildren,
	};
};

export const useFetchPages = (
	currentPageId: string | undefined,
	paginated: boolean,
	forceFetch: boolean,
	detached: boolean,
	spaceKey?: string,
	isSuperAdmin?: boolean,
): QueryResult<PageTreeQueryResponse | PageTreeRootLevelPagesResponse> => {
	const { query, options } = useMemo(
		() => generateQueryArgs(currentPageId, paginated, forceFetch, detached, spaceKey, isSuperAdmin),
		[currentPageId, paginated, forceFetch, detached, spaceKey, isSuperAdmin],
	);

	return useQuery<PageTreeQueryResponse | PageTreeRootLevelPagesResponse>(query, {
		skip: true,
		fetchPolicy: options.fetchPolicy as WatchQueryFetchPolicy,
	});
};

export const useLoadPagesCallback = (
	spaceKey,
	expandSpaceHomepage,
	syntheticItem,
	isPeekingFromBlogs = false,
	isSuperAdmin = false,
	statuses,
	syntheticItemIsDraft,
	selectedPageId,
	pageTreeStateUpdatesContainer,
) => {
	const [state, actions] = usePageTreeState();
	const { queryChildren } = usePageTreeChildren({
		statuses,
		isSuperAdmin,
	});
	return useCallback(
		(data: any) => {
			if (data && Object.keys(data).length > 0) {
				const spaceHomePageId = data.space?.homepage?.id;
				const newState = derriveStateFromData(
					data,
					spaceKey,
					state,
					isPeekingFromBlogs,
					syntheticItem,
					syntheticItemIsDraft,
					state.isShowingBlankDraftsEnabled,
					state.isUnreadPagesEnabled,
					selectedPageId,
					pageTreeStateUpdatesContainer,
					fg('confluence_frontend_whiteboard_emoji_titles'),
				);

				actions.setStateWithUIAttributes(newState);
				const { rootId, pages: allPages } = newState;

				if (
					expandSpaceHomepage &&
					spaceHomePageId &&
					rootId !== spaceHomePageId &&
					allPages[spaceHomePageId]
				) {
					return actions.expandPage(allPages[spaceHomePageId], queryChildren);
				}
				return allPages;
			}
			return data;
		},
		[
			spaceKey,
			state,
			isPeekingFromBlogs,
			syntheticItem,
			syntheticItemIsDraft,
			actions,
			expandSpaceHomepage,
			queryChildren,
			selectedPageId,
			pageTreeStateUpdatesContainer,
		],
	);
};
