import { useEffect } from 'react';

import { isPersonalSpace as checkIsPersonalSpace } from '@confluence/space-utils';
import {
	useGetOnboardingState,
	useOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useLivePageOnboardingNudgeEligible } from '@confluence/onboarding-editor/entry-points/hooks/useLivePageOnboardingNudgeEligible';
import { useIsSpaceGettingStartedTourEligible } from '@confluence/experiment-space-tour';
import { useSampleContentExperiment } from './useSampleContentExperiment';
import { useSampleContentPageTreeSpotlight } from './useSampleContentPageTreeSpotlight';

interface UseSpotlightProps {
	spaceKey?: string;
	isTargetReady: boolean;
	contentId?: string;
}

export const useSpotlight = ({ spaceKey, isTargetReady, contentId }: UseSpotlightProps) => {
	const { setOnboardingState } = useOnboardingState();
	const { sampleContentCohort } = useSampleContentExperiment();
	const isUserInSpotlightTreatment = sampleContentCohort === 'sampleContentWithSpotlight';
	const { data: onboardingStateData } = useGetOnboardingState([
		ONBOARDING_EXPERIENCES.IS_SAMPLE_CONTENT_V2_SPOTLIGHT_VISIBLE,
	]);
	const {
		isSpaceGettingStartedTourExperimentCohort,
		loading: isSpaceGettingStartedTourEligibleLoading,
	} = useIsSpaceGettingStartedTourEligible();
	const { isSampleContentV2SpotlightVisible: shouldUserSeeSpotlight } =
		deserializeState(onboardingStateData);
	const [
		{ hasSampleContentPageTreeSpotlightShown },
		{
			setAreSampleContentPageTreeSpotlightConditionsMet,
			setHasSampleContentPageTreeSpotlightShown,
		},
	] = useSampleContentPageTreeSpotlight();
	const isPersonalSpace = checkIsPersonalSpace(spaceKey);
	const isLivePage = useIsLivePage();
	const { isLoading, isEligibleForLivePageNudge } = useLivePageOnboardingNudgeEligible({
		contentId,
	});
	const hideForLivePageNudge = !isLoading && isLivePage && isEligibleForLivePageNudge;

	const setHasSpotlightShownExternal = () => {
		if (!hasSampleContentPageTreeSpotlightShown) {
			void setHasSampleContentPageTreeSpotlightShown(true);
			setOnboardingState({
				key: ONBOARDING_EXPERIENCES.IS_SAMPLE_CONTENT_V2_SPOTLIGHT_VISIBLE,
				value: 'false',
			});
		}
	};

	useEffect(() => {
		if (
			isUserInSpotlightTreatment &&
			shouldUserSeeSpotlight &&
			!hasSampleContentPageTreeSpotlightShown &&
			isTargetReady &&
			isPersonalSpace &&
			!hideForLivePageNudge &&
			!isSpaceGettingStartedTourExperimentCohort &&
			!isSpaceGettingStartedTourEligibleLoading
		) {
			setTimeout(() => {
				void setAreSampleContentPageTreeSpotlightConditionsMet(true);
			}, 2000);
		}
	}, [
		isTargetReady,
		setAreSampleContentPageTreeSpotlightConditionsMet,
		isPersonalSpace,
		setOnboardingState,
		isUserInSpotlightTreatment,
		isSpaceGettingStartedTourExperimentCohort,
		isSpaceGettingStartedTourEligibleLoading,
		shouldUserSeeSpotlight,
		hasSampleContentPageTreeSpotlightShown,
		hideForLivePageNudge,
	]);

	return {
		setHasSpotlightShown: setHasSpotlightShownExternal,
	};
};
