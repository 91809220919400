import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';
import { Cohorts } from '@confluence/onboarding-helpers/entry-points/constants/cohorts';

/**
 * Eligibility Check Hook for Bold New Pages
 * @param {Boolean} fireExperimentExposure Optional param to fire exposure event. defaults to false
 * @returns {Boolean} User are eligible for bold new pages experiment if they are
 * - Logged In
 * - on Standard Edition
 * - Are part of Experiment Cohort
 */
export const useIsBoldPageTreeExperimentEligible = (fireExperimentExposure = false): boolean => {
	const { isLoggedIn, edition } = useSessionData();

	const isStandardLoggedInUser = isLoggedIn && edition === ConfluenceEdition.STANDARD;

	if (!isStandardLoggedInUser) {
		return false;
	}
	const [expConfig, fireExposureEvent] = UNSAFE_noExposureExp(
		'confluence_onboarding_bold_new_pages_experiment',
	);
	const boldNewPagesCohort = expConfig.get('cohort', 'not-enrolled') as Cohorts;

	if (fireExperimentExposure) {
		fireExposureEvent();
	}

	return boldNewPagesCohort === Cohorts.EXPERIMENT;
};
