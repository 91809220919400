import type { Action } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

type State = {
	areSampleContentPageTreeSpotlightConditionsMet: boolean;
	hasSampleContentPageTreeSpotlightShown: boolean;
};

const initialState: State = {
	areSampleContentPageTreeSpotlightConditionsMet: false,
	hasSampleContentPageTreeSpotlightShown: false,
};

const actions = {
	setAreSampleContentPageTreeSpotlightConditionsMet:
		(value: boolean): Action<State> =>
		({ setState }) => {
			setState({
				areSampleContentPageTreeSpotlightConditionsMet: value,
			});
		},
	setHasSampleContentPageTreeSpotlightShown:
		(value: boolean): Action<State> =>
		({ setState }) => {
			setState({
				hasSampleContentPageTreeSpotlightShown: value,
			});
		},
};

const Store = createStore({
	initialState,
	actions,
});

export const useSampleContentPageTreeSpotlight = createHook(Store);
